<template>
  <div
    v-if="product && product.name"
    class="sidebar__item"
    :class="{
      sidebar__item__disabled: loading,
      'sidebar__item__not-addable': wishlistItemQuantity === 0,
      sidebar__item__disabled: wishlistLoading,
    }"
  >
    <nuxt-link
      :to="localePath(productUrl)"
      class="sidebar__item__image__container"
    >
      <nuxt-img
        class="sidebar__item__image"
        :src="thumbnailUrl"
        alt="product image"
      />
    </nuxt-link>
    <div class="sidebar__item__name">
      <!-- <span class="sidebar__item__name__label">NOME</span> -->
      <span class="sidebar__item__name__value">{{
        simpleProductData.name
      }}</span>
    </div>
    <div class="sidebar__item__sku">
      <span class="sidebar__item__sku__label"> Articolo: </span>
      <span class="sidebar__item__sku__value">
        {{ simpleProductData.sku }}</span
      >
    </div>
    <div
      class="sidebar__item__options"
      v-show="simpleProductData.venditamultiplidiarticoliweb > 1"
    >
      <div class="sidebar__item__options__option">
        <span class="sidebar__item__options__option__label"> Qtà conf: </span>
        <span class="sidebar__item__options__option__value">
          {{ simpleProductData.venditamultiplidiarticoliweb }}
        </span>
      </div>
    </div>
    <div class="sidebar__item__price" v-if="showPrice">
      <span class="sidebar__item__price__special">
        {{ $fc(productPrice.minimum_price.final_price.value) }}
      </span>
      <CustomQuantitySelector
        :key="`qty-selector-${wishlistItemUid}-${forceUpdate}`"
        v-if="isVariant"
        :disabled="!product && wishlistLoading"
        :quantity="wishlistItemQuantity"
        :step="stepSize"
        :min-quantity="0"
        :max-quantity="maximumSaleableQty"
        class="sidebar__item__price__qty"
        v-model="wishlistItemQuantity"
        @change="handleQuantityChange"
      />
    </div>
    <div class="sidebar__item__remove" @click="handleRemove">
      <Cross />
    </div>
    <div
      v-if="isVariant && wishlistItemQuantity > 0 && showPrice"
      class="sidebar__item__add-to-cart"
      :class="{ 'sidebar__item__add-to-cart__disabled': wishlistLoading }"
      @click="handleAddToCart"
    >
      <CartIcon />
    </div>
  </div>
  <div v-else />
</template>

<script>
import {
  defineComponent,
  computed,
  shallowRef,
  useContext,
  useRouter,
  onMounted,
} from '@nuxtjs/composition-api';
import { Cross, CartIcon } from '~/components/General/Icons';
import { useUserWishlists, useCart, useUser } from '~/composables';
import { productGetters } from '@gemini-vsf/composables';
import { CustomQuantitySelector } from '~/components/General/FormElements';

export default defineComponent({
  name: 'WishlistSidebarItem',
  components: { CustomQuantitySelector, Cross, CartIcon },
  props: {
    wishlistItem: {
      type: Object,
      default: () => {},
    },
    wishlistItemUid: {
      type: String,
      required: true,
    },
    wishlistItemGrn: {
      type: String,
      required: true,
    },
    wishlistLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { removeItemFromWishlist, updateItemInWishlist } = useUserWishlists();
    const { getSlug } = productGetters;
    const { addItem } = useCart();
    const { localePath } = useContext();
    const router = useRouter();
    const { user } = useUser();

    const loading = shallowRef(false);
    const currentVariant = computed(() =>
      props.wishlistItem?.product?.variants?.find(
        (v) => v.product.uid === props.wishlistItem.item_grn
      )
    );
    const isVariant = computed(() => props.wishlistItem.item_grn.includes('/'));

    // If venditamultiplidiarticoliweb is greater than 0 and greater than the current wishlist quantity,
    // the quantity is forced to venditamultiplidiarticoliweb.
    // The quantity is only visually adjusted to avoid doing hundreds of API calls on the bigger wishlists
    const getInitialQuantity = () => {
      const wishlistQty = Number.parseInt(props.wishlistItem?.qty, 10);
      const minQty = Number.parseInt(
        currentVariant.value?.product?.venditamultiplidiarticoliweb,
        10
      );
      if (wishlistQty === 0) {
        return wishlistQty;
      }
      return Math.max(wishlistQty, minQty);
    };

    const wishlistItemQuantity = shallowRef(getInitialQuantity());

    onMounted(() => {
      // Emit an object with the key being the product uid and the value being the quantity of
      // venditamultiplidiarticoliweb if venditamultiplidiarticoliweb is greater than the current wishlist quantity
      if (
        Number.parseInt(
          currentVariant.value?.product?.venditamultiplidiarticoliweb,
          10
        ) > Number.parseInt(props.wishlistItem?.qty, 10) &&
        Number.parseInt(props.wishlistItem?.qty, 10) > 0
      ) {
        emit('update:quantity', {
          [props.wishlistItemGrn]: Number.parseInt(
            currentVariant.value?.product?.venditamultiplidiarticoliweb,
            10
          ),
        });
      }
    });

    const stepSize = shallowRef(
      Number.parseInt(
        currentVariant.value?.product?.venditamultiplidiarticoliweb,
        10
      ) || 1
    );
    const maximumSaleableQty = shallowRef(
      Number.parseInt(
        currentVariant.value?.product?.only_x_left_in_stock,
        10
      ) || 99999
    );
    const product = computed(() => props.wishlistItem?.product);

    const productPrice = computed(() =>
      !props?.wishlistItemGrn?.includes('/')
        ? product.value?.price_range
        : product.value?.variants?.find(
            (v) => v?.product?.uid === props?.wishlistItemGrn
          )?.product?.price_range
    );

    const thumbnailUrl = computed(() =>
      product.value?.thumbnail?.url
        ? `${product.value?.thumbnail?.url}?w=200`
        : '/icon.png'
    );

    const productUrl = computed(() => getSlug(product.value));

    const simpleProductData = computed(() => {
      if (!props.wishlistItemGrn.includes('/')) {
        return {
          name: product.value?.name || '',
          sku: product.value?.merchant_sku || '',
        };
      }
      const selectedOptions = currentVariant.value.option_uids;
      const optionValues = selectedOptions.map((o) => {
        const attributeUid = o.split(':')[0];
        const option = product.value.configurable_options.find(
          (p) => p.attribute_uid === attributeUid
        );
        const valueLabel = option.values.find((v) => v.uid === o).label;
        const optionLabel = option.label;
        const optionUid = option.values.find((v) => v.uid === o).uid;
        return {
          label: optionLabel,
          value: valueLabel,
          uid: optionUid,
        };
      });
      return {
        optionValues,
        sku: currentVariant.value?.product.merchant_sku || '',
        name: currentVariant.value?.product.name || '',
        venditamultiplidiarticoliweb:
          currentVariant.value?.product.venditamultiplidiarticoliweb || 1,
      };
    });

    const customerAdditionalInfo = computed(() =>
      JSON.parse(user.value?.additional_info || '{}')
    );

    const isA4 = computed(
      () => customerAdditionalInfo.value?.codiceAgente === 'A     4'
    );

    const isA50 = computed(() =>
      user?.value?.segments?.includes(
        'grn:customer:customer_group::65b3c2ddc1b84cf9cd4e64ac'
      )
    );

    const isBulkyProduct = computed(() =>
      props.wishlistItem?.product?.variants?.some(
        (variant) => variant?.product?.nontrasferibile
      )
    );

    const showPrice = computed(() => {
      const isAllOutOfStock = props.wishlistItem?.product?.variants?.every(
        (variant) =>
          variant?.product?.stock_status === 'OUT_OF_STOCK' ||
          variant?.product?.only_x_left_in_stock === 0
      );
      return (
        !(isBulkyProduct?.value && (isA4?.value || isA50?.value)) &&
        !isAllOutOfStock
      );
    });

    const handleRemove = async () => {
      loading.value = true;
      await removeItemFromWishlist(
        { product: product.value },
        props.wishlistItemUid
      );
      loading.value = false;
    };

    const forceUpdate = shallowRef(0);
    const handleAddToCart = async () => {
      const simpleProductSku = props.wishlistItemGrn.includes('/')
        ? props.wishlistItemGrn
        : null;
      if (!simpleProductSku) {
        router.push({
          path: localePath(productUrl.value),
          query: { addToCart: true },
        });
        return;
      }
      loading.value = true;
      await addItem(
        {
          item: {
            ...product.value,
            configurable_product_options_selection: {
              variant: { sku: simpleProductSku },
            },
          },
          quantity: wishlistItemQuantity.value,
          configurations:
            simpleProductData.value?.optionValues?.map((o) => o?.uid) || [],
        },
        false // don't open cart sidebar
      );
      await updateItemInWishlist({
        wishlist_item_uid: props.wishlistItemUid,
        quantity: 0,
      });
      wishlistItemQuantity.value = 0;
      forceUpdate.value += 1;
      loading.value = false;
    };

    const handleQuantityChange = async (updatedQty) => {
      loading.value = true;
      if (updatedQty !== props.wishlistItem.qty) {
        await updateItemInWishlist({
          wishlist_item_uid: props.wishlistItemUid,
          quantity: Number.parseInt(updatedQty, 10),
        });
        wishlistItemQuantity.value = Number.parseInt(updatedQty, 10);
      }
      loading.value = false;
    };

    return {
      handleRemove,
      handleAddToCart,
      loading,
      thumbnailUrl,
      productUrl,
      simpleProductData,
      productPrice,
      showPrice,
      wishlistItemQuantity,
      handleQuantityChange,
      product,
      stepSize,
      maximumSaleableQty,
      isVariant,
      forceUpdate,
    };
  },
});
</script>

<style lang="scss" scoped>
.sidebar__item {
  display: grid;
  grid-template-columns: 30% 1fr 20% 20%;
  grid-template-areas:
    'image name name remove'
    'image sku options options'
    'image price price add-to-cart';
  gap: 0.5rem;
  border-bottom: 1px solid var(--c-grey);
  padding: 1rem 0;
  &__image {
    &__container {
      grid-area: image;
      @include flex-center;
    }
    width: 70%;
    height: 70%;
    object-fit: contain;
  }
  &__name {
    grid-area: name;
    word-break: break-word;
    padding-top: 0.2rem;
    @include text-13x16-black;
  }
  &__sku {
    grid-area: sku;
    @include text-13x16-regular;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__price {
    grid-area: price;
    display: flex;
    gap: 1rem;
    align-items: center;
    &__regular {
      &__barred {
        text-decoration: line-through;
        color: var(--c-red-error);
      }
    }
    &__regular,
    &__special {
      @include text-13x16-regular;
    }
  }
  &__remove {
    grid-area: remove;
  }
  &__add-to-cart {
    grid-area: add-to-cart;
  }
  &__remove,
  &__add-to-cart {
    @include flex-center;
    cursor: pointer;
    margin: 0.3rem;
    scale: 0.8;
    &__disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
  &__disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  &__not-addable {
    background-color: var(--c-primary-ultralight);
  }
  &__options {
    grid-area: options;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    &__option {
      display: flex;
      flex-direction: row;
      gap: 0.2rem;
      &__label {
        @include text-13x16-regular;
      }
      &__value {
        @include text-13x16-regular;
        font-weight: 600;
      }
    }
  }
}
@include from-desktop-min {
  .sidebar__item {
    &__price {
      //gap: 1rem;
      flex-direction: row;
    }
  }
}
@media (max-width: 400px), (min-width: 1024px) and (max-width: 1350px) {
  .sidebar__item {
    &__price {
      flex-direction: column;
      justify-content: flex-start;
    }
  }
}
</style>

<style lang="scss">
.sidebar__item {
  &__price {
    &__qty {
      position: relative;
      .custom-qty-selector {
        &__selector {
          &.sf-quantity-selector {
            height: 1.5rem;
            max-width: 10rem;
            input {
              font-size: 0.8125rem;
            }
          }
        }
        &__error {
          position: absolute;
          z-index: 2;
          background-color: white; //rgba(255, 255, 255, 0.75); //semi-transparent white
          font-size: 0.8125rem;
          bottom: 1.5rem;
          left: 50%;
          transform: translateX(-50%);
          width: 100%;
          //border: 1px solid var(--c-red-error);
          padding: 0.125rem;
          border-radius: 5px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
          line-height: 1.1rem;
        }
      }
    }
  }
}
</style>
